import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { indexOf } from 'lodash'

import "react-datepicker/dist/react-datepicker.css"

// Field Types
import DatePicker from "react-datepicker"
import InputField from './fields/inputField'
import Select from 'react-select'
import YearsOfService from './fields/yearsOfService'
import ReviewField from './fields/reviewField'
import MemberResultTabs from './fields/memberResultTabs'
import MemberResultsTable from './fields/memberResultsTable'

// Reducers
import {
  updateData,
} from '../reducers/data'

class Field extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange( fieldValue ) {
        this.props.updateData( this.props.name, fieldValue, this.props.type );
    }
    componentDidMount() {
        if( !!this.props.fieldValue.value && this.props.type !== "review" ) this.handleChange( this.props.fieldValue.value );
    }
    render() {
        // const currentAnnualSalaryField = this.props.fields.find(field => field.name === "current_annual_salary");
        // if (currentAnnualSalaryField) {
        //     console.log('current_annual_salary', currentAnnualSalaryField);
        // }
        // console.log('current_annual_salary', current_annual_salary);


        let { value, type } = this.props.fieldValue
        let { isValid } = this.props
        let theField = null
        let theLabel = <label htmlFor={this.props.name}>{this.props.label} {!!this.props.required ? '*' : ''}</label>
        let theValidation = isValid ? null : <div className="validation-text"><span>This field is required</span></div>
        switch( this.props.type ) {
            case 'years-of-service-chart' :
                theField = <YearsOfService {...this.props} value={value?value:{}} onChange={this.handleChange} />
                break;
            case 'dropdown' :
                theField = <Select {...this.props} value={value} onChange={this.handleChange} />
                break;
            case 'datepicker' :
                theField = <label><DatePicker {...this.props} id={this.props.name} selected={!!value ? value : null} onChange={this.handleChange} /><div className="calender-ico"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z"/></svg></div></label>
                break;
            case 'review' :
                theField = <ReviewField {...this.props} value={value} fieldType={type} />
                break;
            case 'memberResultTabs' :
                theLabel = null;
                theField = <MemberResultTabs {...this.props} />
                break;
            case 'memberResultsTable' :
                theLabel = null;
                theField = <MemberResultsTable {...this.props} />
                break;
            default :
                theField = <InputField {...this.props} value={value} onChange={this.handleChange} />
        }
        return (
            <div className={"field-wrap field-wrap-"+this.props.type+( !isValid ? ' invalid' : '' )}>{ theLabel }{ theField }{ theValidation }</div>
        );
    }
}

const mapStateToProps = (state,props) => {
    let isValid = true;
    if( !!state.validation[state.nav.currentNav] ) {
        isValid = indexOf( state.validation[state.nav.currentNav], props.name ) === -1
    }
    return {
        fieldValue: !!state.data.fields[props.name]?state.data.fields[props.name]:(!!props.defaultVal?{value:props.defaultVal,type:props.type}:'' ),
        isValid
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateData
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Field)